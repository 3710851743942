"use client";
import { useEffect, useRef } from "react";

const AdSlot = ({
  adUnitPath,
  divId,
  sizes,
  style,
  className,
  sizeMapping,
}) => {
  const hasDisplayed = useRef(false);

  useEffect(() => {
    if (typeof window === "undefined") return;

    const tryDisplay = () => {
      const container = document.getElementById(divId);
      if (!container) {
        requestAnimationFrame(tryDisplay);
        return;
      }

      if (hasDisplayed.current) return;
      hasDisplayed.current = true;

      window.googletag = window.googletag || { cmd: [] };

      window.googletag.cmd.push(() => {
        window.googletag._slots = window.googletag._slots || new Set();

        if (!window.googletag._slots.has(divId)) {
          let slot = window.googletag.defineSlot(adUnitPath, sizes, divId);

          if (slot) {
            // ✅ Apply size mapping if provided
            if (
              sizeMapping &&
              typeof window.googletag.sizeMapping === "function"
            ) {
              const mapping = window.googletag.sizeMapping();
              sizeMapping.forEach(([viewport, slotSizes]) => {
                mapping.addSize(viewport, slotSizes);
              });
              slot = slot.defineSizeMapping(mapping.build());
            }

            slot.addService(window.googletag.pubads());
            window.googletag._slots.add(divId);
          }

          if (!window.googletag._servicesEnabled) {
            window.googletag.pubads().enableSingleRequest();
            window.googletag.pubads().collapseEmptyDivs();
            window.googletag.enableServices();
            window.googletag._servicesEnabled = true;
          }
        }

        console.log("📡 Displaying slot:", divId);
        window.googletag.display(divId);
      });
    };

    tryDisplay();
  }, [adUnitPath, divId, sizes, sizeMapping]);

  return <div id={divId} style={style} className={className}></div>;
};

export default AdSlot;
