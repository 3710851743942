"use client";
import React, { useState, useEffect, useRef } from "react";
import Link from "next/link";
import parse from "html-react-parser";
import Image from "next/image";
import dayjs from "dayjs";
import CategoryDropdown from "../../common/category-dropdown"

function Topics({ allPosts }) {

  const [selectedCategory, setSelectedCategory] = useState("All");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const capitalizeFirstLetter = (str) => {
    if (!str || typeof str !== "string") {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setIsDropdownOpen(false);
  };

  const categories = ["All", ...new Set(allPosts.flatMap(post => post.categories_with_names?.map(category => category.name)))];

  const filteredPosts = selectedCategory === "All"
    ? allPosts
    : allPosts.filter(post => post.categories_with_names?.some(category => category.name === selectedCategory));

  return (
    <div className="bg-white py-6 px-4 rounded-lg mb-0">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-bold text-red-500">Topics</h2>
        <div className="flex gap-4">
          <ul className="flex items-center gap-4">
            {["Asia", "Business"].map((category) => (
              <li
                key={category}
                className="text-gray-600 cursor-pointer font-slab text-sm"
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </li>
            ))}
            <CategoryDropdown
              categories={categories}
              selectedCategory={selectedCategory}
              onSelectCategory={handleCategorySelect}
            />
          </ul>
        </div>
      </div>

      <div className="space-y-4">
        {filteredPosts.slice(0, 1).map((post, index) => (
          <article key={index} className="bg-white border-b border-gray-300 group">
            <Link prefetch={true} href={`/${post?.slug}`}>
              <div className="relative aspect-[4/3]">
                <img
                  src={post?.featured_image_url || "/placeholder.svg"}
                  alt={post?.title?.rendered}
                  className="w-full h-full object-cover"
                />
              </div>
            </Link>
            <div className="py-4 space-y-3">
              <h3 className="text-2xl font-bold group-hover:text-red-500 line-clamp-4">
                <Link prefetch={true} href={`/${post.slug}`} className="font-bold text-black" >
                  {parse(post?.title?.rendered || "")}
                </Link>
              </h3>
              <div className="flex items-center flex-wrap gap-2 text-sm mb-2">
                {post?.categories_with_names
                  ?.filter((category) => category?.name !== "Featured News") // Exclude "Featured News"
                  .map((category, index, filteredCategories) => (
                    <React.Fragment key={category?.id}>
                      <Link href={`/news/${category?.slug}`} className="leading-tight">
                        <span className="text-red-500 font-bold text-sm">
                          {capitalizeFirstLetter(category?.name)}
                        </span>
                      </Link>
                      {index < filteredCategories.length - 1 && (
                        <span className="text-gray-300 text-xs">|</span>
                      )}
                    </React.Fragment>
                  ))}
                <span className="text-gray-400">-</span>
                <span className="text-black">
                  {dayjs(post?.date).format("MMMM D, YYYY")}
                </span>
              </div>
            </div>
          </article>
        ))}

        {filteredPosts.slice(1, 11).map((post, index) => (
          <article
            key={index}
            className="pb-2 border-b border-gray-300 last:border-b-0 last:pb-0"
          >
            <h3 className="font-bold text-gray-800 leading-snug text-black hover:text-red-600 transition-colors mb-3">
              <Link prefetch={true} href={`/${post.slug}`} className="line-clamp-2">
                {parse(post?.title?.rendered || "")}
              </Link>
            </h3>
            <div className="flex items-center flex-wrap gap-2 text-sm mb-2">
              {post?.categories_with_names
                ?.filter((category) => category?.name !== "Featured News") // Exclude "Featured News"
                .map((category, index, filteredCategories) => (
                  <React.Fragment key={category?.id}>
                    <Link prefetch={true} href={`/news/${category?.slug}`} className="leading-tight">
                      <span className="text-red-500 font-bold text-sm">
                        {capitalizeFirstLetter(category?.name)}
                      </span>
                    </Link>
                    {index < filteredCategories.length - 1 && (
                      <span className="text-gray-300 text-xs">|</span>
                    )}
                  </React.Fragment>
                ))}
              <span className="text-gray-400">-</span>
              <span className="text-black">
                {dayjs(post?.date).format("MMMM D, YYYY")}
              </span>
            </div>
          </article>
        ))}
      </div>
      <div className="mt-12 w-full">
        <Link prefetch={true}
          href="/latest"
          className="bg-black text-white px-8 flex items-center justify-center py-4 hover:bg-red-500 w-full"
        >
          View all
        </Link>
      </div>
    </div>
  );
}

export default Topics;
