import(/* webpackMode: "eager" */ "/mnt/volume_sgp1_02/app/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/mnt/volume_sgp1_02/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/mnt/volume_sgp1_02/app/src/components/common/ad-slot.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/mnt/volume_sgp1_02/app/src/components/common/AdSenseResponsive.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/mnt/volume_sgp1_02/app/src/components/common/google-ad/google-adsense.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/mnt/volume_sgp1_02/app/src/components/common/layout.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/mnt/volume_sgp1_02/app/src/components/home/components/main-article.js");
;
import(/* webpackMode: "eager", webpackExports: ["MalasiyaNews"] */ "/mnt/volume_sgp1_02/app/src/components/home/components/malaysia-news.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/mnt/volume_sgp1_02/app/src/components/home/components/topics.js");
