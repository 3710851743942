'use client';

import React, { useState } from "react";
import Link from "next/link";
import dayjs from "dayjs";
import parse from "html-react-parser";

// Optimized Image Component with loading state
const OptimizedImage = ({ src, alt }) => {
  const [isLoading, setIsLoading] = useState(true);
  
  // Generate optimized URLs for different sizes
  const optimizedSrc = `${src}?w=800&h=450&auto=format,compress&q=75`; // Main image
  const thumbnailSrc = `${src}?w=50&h=28&blur=10&auto=format`; // Tiny placeholder
  
  return (
    <>
      {/* Low quality placeholder */}
      <img
        className={`absolute m-0 top-0 left-0 w-full h-full object-cover transition-opacity duration-300 ${
          isLoading ? 'opacity-100' : 'opacity-0'
        }`}
        src={thumbnailSrc}
        alt=""
        aria-hidden="true"
      />
      
      {/* Main image */}
      <img
        className={`absolute m-0 top-0 left-0 w-full h-full object-cover transition-opacity duration-300 ${
          isLoading ? 'opacity-0' : 'opacity-100'
        }`}
        src={optimizedSrc}
        alt={alt}
        loading="eager"
        fetchPriority="high"
        sizes="(max-width: 768px) 100vw, 800px"
        decoding="async"
        onLoad={() => setIsLoading(false)}
        style={{
          transform: 'translate3d(0, 0, 0)', // Hardware acceleration
          backfaceVisibility: 'hidden',
          WebkitBackfaceVisibility: 'hidden'
        }}
      />
    </>
  );
};

function MainArticle({ post }) {
  const capitalizeFirstLetter = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  };

  // Memoize parsed content to prevent unnecessary re-renders
  const parsedContent = React.useMemo(() => 
    parse(post?.content?.rendered || ""),
    [post?.content?.rendered]
  );

  // Memoize parsed title
  const parsedTitle = React.useMemo(() => 
    parse(post?.title?.rendered || ""),
    [post?.title?.rendered]
  );

  return (
    <article className="mb-8 about-tisg">
      <Link 
        href={`/${post?.slug}`} 
        className="block aspect-video relative mb-4"
        prefetch={true}
      >
        <div
          className="post-img relative overflow-hidden"
          style={{ 
            width: "100%", 
            height: "100%", 
            paddingBottom: "86.25%",
            transform: 'translate3d(0, 0, 0)' // Hardware acceleration
          }}
        >
          <OptimizedImage 
            src={post?.featured_image_url} 
            alt={post?.title?.rendered || "Article thumbnail"} 
          />
        </div>
      </Link>

      <div className="space-y-4 xl:ml-16 mt-6 border-b border-b-gray-300 pb-6 mx-4 xl:mr-0">
        <h1 className="text-4xl font-bold hover:text-red-500">
          <Link href={`/${post?.slug}`} prefetch={true}>
            {parsedTitle}
          </Link>
        </h1>

        <div className="flex items-center gap-2 text-xl">
          <div className="flex gap-2 text-lg text-gray-700 items-center">
            {post?.categories_with_names
              ?.filter((category) => category?.name !== "Featured News")
              .map((category, index, filteredCategories) => (
                <React.Fragment key={category?.id}>
                  <Link 
                    href={`/news/${category?.slug}`} 
                    className="leading-tight"
                    prefetch={true}
                  >
                    <span className="text-red-500 font-bold text-sm">
                      {capitalizeFirstLetter(category?.name)}
                    </span>
                  </Link>
                  {index < filteredCategories.length - 1 && (
                    <span className="text-gray-300 text-xs">|</span>
                  )}
                </React.Fragment>
              ))}
          </div>

          <time 
            className="text-sm font-semibold ml-4"
            dateTime={post?.date}
          >
            {dayjs(post?.date).format("MMMM D, YYYY")}
          </time>
        </div>

        <div className="font-slab line-clamp-3">
          {parsedContent}
        </div>
      </div>
    </article>
  );
}

// Memoize the entire component to prevent unnecessary re-renders
export default React.memo(MainArticle);
