"use client"

import React, { useState } from "react";
import Image from "next/image";
import Link from "next/link";
import parse from "html-react-parser";
import dayjs from "dayjs";
import AdSense from "@/components/common/google-ad/google-adsense";

export function MalasiyaNews({ posts }) {
  const [morePosts, setMorePosts] = useState(14);

  const loadMorePosts = () => {
    setMorePosts((prevMorePosts) => prevMorePosts + 14);
  };

  const capitalizeFirstLetter = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  };

  const renderPosts = () => {
    const postsToRender = posts.slice(0, morePosts);
    const items = [];

    postsToRender.forEach((post, index) => {
      // Add the post
      items.push(
        <article key={`post-${index}`} className="flex flex-col space-y-4">
          <Link href={`/${post?.slug}`} className="group">
            <div className="relative aspect-[4/2.75] overflow-hidden">
              <img
                src={post?.featured_image_url}
                alt={post?.title?.rendered}
                className="object-cover h-full w-full transition-transform duration-200 group-hover:scale-105"
              />
            </div>
            <h2 className="mt-4 text-xl font-bold leading-snug tracking-tight text-gray-900 group-hover:text-red-500 line-clamp-3">
              {parse(post?.title?.rendered || "")}
            </h2>
          </Link>
          <div className="flex items-center gap-4">
            <span className="text-red-500 font-medium">
              {post?.categories_with_names?.length > 0 &&
                post?.categories_with_names
                  ?.filter(category => category?.name === "Malaysia")
                  .map((category) => (
                    <Link href={`/news/${category?.slug}`} key={category?.id} style={{lineHeight:1}}>
                      <span className="text-red-500 font-bold text-sm">
                        {capitalizeFirstLetter(category?.name)}
                      </span>
                    </Link>
                  ))}
            </span>
            <span>{dayjs(post?.date).format("MMMM D, YYYY")}</span>
          </div>
          <div className="text-md font-slab line-clamp-3">
            {parse(post?.content?.rendered.replace(/data-src="([^"]+)"/g, 'src="$1"'))}
          </div>
        </article>
      );

      // Add AdSense after every 5th post (except the last post)
      if ((index + 1) % 5 === 0 && index !== postsToRender.length - 1) {
        items.push(
          <div key={`ad-${index}`} className="flex flex-col space-y-4">
            <span className="text-balck text-sm opacity-50 tracking-wide">ADVERTISEMENT</span>
            <AdSense />
          </div>
        );
      }
    });

    return items;
  };

  return (
    <section className="text-black">
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6">
        {renderPosts()}
      </div>
      {morePosts < posts.length && (
        <div className="mt-8 w-full flex justify-center">
          <button
            onClick={loadMorePosts}
            className="bg-black text-white px-8 flex items-center justify-center py-4 hover:bg-red-500 mb-4"
          >
            Load More
          </button>
        </div>
      )}
    </section>
  );
}
